<template>
  <div style="display: flex; align-items: center">
    <div class="add-img" :style="{ width: width + 'px', height: height + 'px' }">
      <template v-if="imgUrl">
        <div class="img" @mouseover="showRmove = !0" @mouseout="showRmove = !1">
          <el-image :src="imgUrl" :fit="scale - down"></el-image>
          <span v-show="showRmove" class="remove">
            <span class="item-delete" @click.stop="remove"><i class="el-icon-delete"></i></span>
          </span>
        </div>
      </template>
      <div
        v-else
        :class="['select', showBorder ? 'select-active' : '']"
        @click="handleShowImgLibrary"
        @mouseover="showBorder = !0"
        @mouseout="showBorder = !1"
      >
        <i class="el-icon-plus"></i>
      </div>
    </div>
    <ImgLibrary
      v-if="showImgLibrary"
      :url="url"
      :type="type"
      @close="close"
      @changeStatus="changeStatus"
      :dialogVisible="showImgLibrary"
      @getImg="getImg"
    ></ImgLibrary>
  </div>
</template>

<script>
import ImgLibrary from '@/components/imgLibrary'
export default {
  components: {
    ImgLibrary
  },
  props: {
    // 展示素材的宽
    width: {
      type: Number,
      default: 150
    },
    // 展示素材的高
    height: {
      type: Number,
      default: 150
    },
    // 默认展示素材的路径
    path: {
      type: String,
      default: ''
    },
    // 处理商品 多规格下标记录
    index: {
      default: ''
    },
    /**
     * 类别判断
     * 0-站点内正常上传图片
     * 1-站点外上传图片
     * 2-直播上传图片素材
     */
    url: {
      type: Number,
      default: 0
    },
    // 区别要使用的组件类型 image video
    type: {
      type: String,
      default: 'image'
    },
    // 是否能上传多个素材
    isMany: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    path: {
      handler(val) {
        this.imgUrl = val || ''
      },
      immediate: true
    },
    dialogVisible(val) {
      this.$emit('changeStatus', val)
    }
  },
  data() {
    return {
      showRmove: !1,
      showBorder: !1,
      showImgLibrary: !1,
      imgUrl: ''
    }
  },
  methods: {
    changeStatus(val) {
      this.showImgLibrary = val
    },
    close() {
      this.showImgLibrary = !1
    },
    handleShowImgLibrary() {
      this.showImgLibrary = !0
      this.showRmove = !1
    },
    // 删除图片
    remove() {
      this.imgUrl = ''
      this.$emit('getImg', this.imgUrl, this.index)
    },
    // 获取到素材组件返回的素材列表
    getImg(val) {
      this.showImgLibrary = !1
      if (this.url === 2) {
        this.imgUrl = val.path
        this.$emit('getImg', val, this.index)
      } else {
        this.imgUrl = val[0].path
        this.$emit('getImg', this.imgUrl, this.index)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.add-img {
  position: relative;
  border-radius: 5px;

  .img {
    width: 100%;
    height: 100%;
    border: 1px solid #c0ccda;
    border-radius: 5px;
  }
  .el-image {
    width: 100%;
    height: 100%;
    border-radius: 5px;
  }
  .select {
    width: 100%;
    height: 100%;
    background-color: #fbfdff;
    border: 1px dashed #c0ccda;
    border-radius: 5px;
    cursor: pointer;
    vertical-align: top;
    display: flex;
    align-items: center;
    justify-content: center;
    i {
      font-size: 28px;
      color: #8c939d;
    }
  }
  .select-active {
    border-color: #409eff;
  }
  .remove {
    display: flex;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    cursor: default;
    text-align: center;
    color: #fff;
    opacity: 1;
    font-size: 20px;
    background-color: rgba(0, 0, 0, 0.5);
    transition: opacity 0.3s;
    z-index: 2;
  }
}
</style>
